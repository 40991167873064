import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import PhoneNumberInput from 'atoms/PhoneNumberInput';

/* eslint-disable indent */
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextInput from 'atoms/TextInput';
import { generateObjectArray } from 'utils/helpers';
import { required } from 'utils/validations';

/**
 *
 * PatientContact
 *
 */

const PatientContact = ({ data, step, auth, previousStep}) => {
	const [phoneNumber, setPhoneNumber] = React.useState(data.phone || '');
	const [homePhone, setHomePhone] = React.useState(null);
	const [email, setEmail] = React.useState(auth.email || '');
	const [canSendEmail, setCanSendEmail] = React.useState('yes');
	const [canLeaveVoicemail, setCanLeaveVoicemail] = React.useState('yes');

    
	const prevStep = () => {
		previousStep();
	};

	const nextStep = () => {
		const data = {
			contact: {
				phoneNumber,
				homePhone: homePhone || '',
				email,
				canSendEmail,
				canLeaveVoicemail,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.id = auth.id;
		data.q = keys.join();

		step(data);
	};

    const handleNumberChange = (id, values) => {
        if (id === 'phone') {
            setPhoneNumber(values.value);
        } else if (id === 'homePhone') {
            setHomePhone(values.value);
        };
	};

	const isFormComplete = () => {
		return (
			phoneNumber?.length >= 10 &&
			Boolean(email)
		);
	};

	return (
		<Form>
			<div className="form-row">
				<div className="col-md-6 form-group">
					<PhoneNumberInput
						id="phone"
						label="MOBILE NO.*"
						value={phoneNumber}
						placeholder="Enter 10 Digit Mobile Number"
						handleInput={handleNumberChange}
						className="mb-0"
						isRequired
					/>
				</div>

				<div className="col-md-6 form-group">
					<PhoneNumberInput
						id="homePhone"
						label="HOME NO. (SECONDARY PHONE)"
						value={homePhone}
						placeholder="Enter 10 Digit Home Number"
						handleInput={handleNumberChange}
						className="mb-0"
					/>
				</div>
			</div>
			<div className="col-14 form-group">
				<TextInput
					label="EMAIL ADDRESS*"
					id="email"
					value={email}
					placeholder="john@gmail.com"
					handleInput={(event) => setEmail(event.target.value)}
					validations={[required]}
					//className="w-100"
				/>
			</div>
            <label className="form-font mb-3">
                PERMISSION TO CONTACT YOU REGARDING YOUR CARE:
            </label>
            <div className="form-row">
                <div className="col-md-6 form-group">
                    <label htmlFor="canSendEmail" className="form-font">
                        CAN WE SEND EMAIL?
                    </label>
                    <SelectButtonGroup
                        id="canSendEmail"
                        value={canSendEmail}
                        buttons={generateObjectArray(['Yes', 'No'])}
                        handleSelect={(e) => setCanSendEmail(e.target.value)}
                    />
                </div>
                <div className="col-md-6 form-group">
                    <label htmlFor="canLeaveVoicemail" className="form-font">
                        CAN WE LEAVE VOICEMAIL?
                    </label>
                    <SelectButtonGroup
                        id="canLeaveVoicemail"
                        value={canLeaveVoicemail}
                        buttons={generateObjectArray(['Yes', 'No'])}
                        handleSelect={(e) => setCanLeaveVoicemail(e.target.value)}
                    />
                </div>
            </div>
			<div className="d-flex mt-3">
				<div className="col-6 pl-0">
					<button
						type="button"
						className="btn btn-white link-color border btn-lg btn-block font-weight-bold d-flex justify-content-start font-15"
						onClick={prevStep}
					>
						<div className="col-4 d-flex justify-content-start align-self-center">
							<img className="fas fa-arrow-left" alt="arrow-left" />
						</div>
						<div className="col-4 d-flex justify-content-center">
							<span>PREV</span>
						</div>
					</button>
				</div>
				<div className="col-6 p-0 d-flex">
					<button
						type="button"
						className="btn btn-primary btn-color btn-lg btn-block font-weight-bold font-15"
						onClick={nextStep}
						disabled={!isFormComplete()}
					>
						NEXT
					</button>
				</div>
			</div>
		</Form>
	);
};

PatientContact.propTypes = {
	patientData: PropTypes.object.isRequired,
	step: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	previousStep: PropTypes.func.isRequired,
	states: PropTypes.array,
	cities: PropTypes.object,
	zipcodes: PropTypes.object,
	handleGeoChange: PropTypes.func.isRequired,
};

export default PatientContact;
