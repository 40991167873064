import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import './index.css';

/**
 *
 * Settings
 *
 */

/* eslint-disable react/prefer-stateless-function */
class Settings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: true,
			editName: false,
			name: '',
		};
	}

	editForName = () => {
		const { editName } = this.state;

		this.setState({ editName: !editName });
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	componentDidMount() {
		const { user } = this.props;

		this.setState({ name: user.name });
	}

	handleNameSubmit = () => {
		const { editName, name } = this.state;
		const { user, updateName } = this.props;

		if (name === '') {
			return;
		}

		const data = {
			id: user.id,
			name,
		};

		updateName(data);
		this.setState({ editName: !editName });
	};

	updatePhone = () => {
		const { toggle, togglePhone } = this.props;

		toggle();
		togglePhone();
	};

	updateEmail = () => {
		const { toggle, toggleEmail } = this.props;

		toggle();
		toggleEmail();
	};

	updatePassword = () => {
		const { toggle, togglePassword } = this.props;

		toggle();
		togglePassword();
	};

	render() {
		const { editName, name } = this.state;
		const { toggle, user, serverVersion } = this.props;
		const role = user.roleName;

		return (
			<div>
				<Modal
					className="modal-dialog-centered"
					isOpen={this.state.modal}
					toggle={toggle}
				>
					<ModalHeader toggle={toggle}>
						<div className="container">
							<div className="d-flex justify-content-start mr-4 col-12 p-0 mt-2">
								<i className="fas fa-user-circle fa-4x col-2 p-0" />
								<div className="mr-4 col-10">
									{!editName ? (
										<div key={1} className="d-flex">
											<h3 className="m-0 heading-settings">{user.name}</h3>
											<div className="pl-2" onClick={this.editForName}>
												<span
													className={!editName ? 'd-inline-block' : 'd-none'}
												>
													<i className="fas fa-pen pen" />
												</span>
											</div>
										</div>
									) : (
										<div key={2} className="w-100 d-flex">
											<input
												value={name}
												onChange={this.handleChange}
												type="text"
												name="name"
												className="form-control col-10"
												placeholder="Enter Name"
											/>
											<div
												className="btn-color col-2 btn-settings p-0 d-flex justify-content-center"
												onClick={this.handleNameSubmit}
											>
												<span
													className={!editName ? 'd-inline-none' : 'd-block'}
												>
													<i className="fas fa-check text-white pen p-0" />
												</span>
											</div>
										</div>
									)}
									<p className="text-muted m-0 title">{user.email}</p>
									<span className="badge badge-dark role">
										{user.roleName && user.roleName.toUpperCase()}
									</span>
								</div>
							</div>
						</div>
					</ModalHeader>
					<ModalBody className="p-0">
						<div>
							{!(role === 'admin') && (
								<div>
									{role === 'clinician' && (
										<div>
											<div className="container option hand">
												Update Credentials
											</div>
											<hr className="m-0" />
										</div>
									)}
									<div
										className="container option hand"
										onClick={this.updatePhone}
									>
										Update Phone Number
									</div>
									<hr className="m-0" />
								</div>
							)}
							<div className="container option hand" onClick={this.updateEmail}>
								Update Email
							</div>
							<hr className="m-0" />
							<div
								className="container option hand"
								onClick={this.updatePassword}
							>
								Update Password
							</div>
							<hr className="m-0" />
							<div className="d-flex flex-row-reverse">
								<div className="col-auto px-0 text-right font-10 m-1 text-muted">
									Server: {serverVersion || '-'}
								</div>
								<div className="col-auto px-0 text-right font-10 m-1 text-muted">
									Client: v20241009
								</div>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

Settings.propTypes = {
	toggle: PropTypes.func.isRequired,
	toggleEmail: PropTypes.func.isRequired,
	togglePassword: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	updateName: PropTypes.func.isRequired,
};

export default Settings;
